<template>
    <div>
        <div class="empty_state">
            <img src="@/assets/images/empty_img.png" alt="">
            <span>{{ $t('您还没有收到任何排名奖励，所以去投注并参与活动吧.') }}</span>
        </div>
    </div>
</template>

<script>
import { jackpot_list, jackpot_rule_detail, jackpot_rewards } from "@/api/user";
export default {
    data() {
        return {
            rewardList: []
        }
    },
    mounted(){
        this.fetchMyRewards()
    },
    methods:{
        async fetchMyRewards() {
            let params = {
                page: 1,
                limit: 99999,
            }
            const response = await jackpot_rewards(params)
            if (response.code == 1) {
                this.rankListData = response.data.data
            }
        },
    }
}
</script>

<style lang="less">
.empty_state {
    width: 60%;
    margin: 60px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 54px;
    text-align: center;
}
</style>